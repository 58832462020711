<div class="landing-wrapper">
    <div class="welcome-statement">
        <h3>Safeguarding Children when a mother is at risk of custody</h3>
        <p>
            In Scotland between 1500 and 2000 children are affected by the imprisonment of their mother each year.
            Only 5% of these children will remain in the family home while their mother is in prison.
            These films illustrate the impact imprisonment can have on children when their mother is imprisoned and
            why it is so important that mothers at risk of custody tell their solicitor or social worker that they have children
        </p>
    </div>
    <div class="film-wrapper">
        <iframe width="560" height="315" 
        src="https://www.youtube.com/embed/cNDi0BlN8Gk?start=1" 
        title="YouTube video player" frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowfullscreen></iframe>
        <p>This film provides advice to mothers at risk of custody, and explains why it is important that the courts know they have dependant children.
        </p>
    </div>
    <div class="film-wrapper">
        <iframe width="560" height="315" 
        src="https://www.youtube.com/embed/WOmjOw7nFuI" 
        title="YouTube video player" frameborder="0"
         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
         allowfullscreen></iframe>
        <p>
           <q>They didn’t just sentence me. They sentenced my boys</q> - Ang.
           A true moving story of the enormous impact a prison sentence had on one woman and her 2 sons. 
           Ang was remanded into custody unexpectedly, her children were at school, she had no opportunity to prepare her boys,
           or tell them what was happening. Watch Ang tell what happened next.</p>
    </div>

    <div class="additional-info">
        <span>Additional information</span>
        <ul>
            <li>
                The impact on children when mothers are involved in the
                criminal justice system <a href="http://www.prisonreformtrust.org.uk/portals/0/documents/what%20about%20me.pdf" target="_blank">What about me?</a>
            </li>
            <li>
               <a href="http://www.prisonreformtrust.org.uk/Portals/0/Documents/Women/0808Why%20Women%20Scotland%202018%20data.pdf" target="_blank"> Why focus on reducing womens imprisonment? - Scotland </a>
            </li>
        </ul>
    </div>
</div>