import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AboutMeComponent } from './app/about-me/about-me.component';
import { LandingPageComponent } from './app/landing-page/landing-page.component';

const routes = [
   { path: 'app', redirectTo: '/home', pathMatch: 'full'},
   { path: '', redirectTo: '/home', pathMatch: 'full'},
   { path: 'home', component: LandingPageComponent},
   { path: 'about', component: AboutMeComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
