<nav>
    <img width="200" height="50" src="../../assets/images/logo_v2.png" alt="c j pinkman logo">
    <div >
      <ul>
        <li [routerLink]="['home']">
          <a routerLinkActive="active" routerLink="home" >Home</a>
        </li>
        <li [routerLink]="['about']">
          <a routerLinkActive="active" routerLink="about" >About</a>
        </li>
      </ul>
    </div>
  </nav>
