<img src="../../assets/images/FullSizeRender.jpeg" alt="my bio picture">

<p>
    My name is Anne Pinkman. I have extensive experience of the criminal and community justice system. Previous posts include </p>
<ul>
    <li>
        Head of Criminal Justice Social Work Services with Stirling Council
    </li>
    <li>
        Chief Officer Fife and Forth Valley Community Justice Authority
    </li>
    <li>
        Programme Manager Scotland & Northern Ireland with the Prison Reform Trust
    </li>
</ul>


<p>
 I have been a member of many Scottish Government Groups establishing policy and practice 
 within criminal justice social work and have given evidence to the Scottish Parliament Committees on numerous occasions. <br>
I established the Scottish Working Group d for Women Offenders (SWGWO) <br><br>
I provide the Secretariat to the Scottish Parliament Cross Party Group on Women & Justice <br><br>
My interests include women who offend, children affected by parental imprisonment,  mental health and violence against women.<br>
Publications I have been involved in include: <br><br>
<a href="http://www.prisonreformtrust.org.uk/portals/0/documents/what%20about%20me.pdf" target="_blank">What about me?</a>  The impact on children when mothers are involved in the criminal justice system <br>
Kelly Lockwood, edit, 2020, Mothering form the Inside,research on motherhood and imprisonment, Emerald Publishing Ltd, Bingley, UK<br>
Scottish Justice Matters, <a href="http://scottishjusticematters.com/the-journal/women-justice-november-2017" target="_blank"> Women and Justice</a>, Are we Making a Difference?, edited Anne Pinkman 
</p>
